import React, { Component } from "react"
import styled from "@emotion/styled"

import ShowColors from "../ShowColors"
import Input from "../atoms/Input"
import { theme } from "../particles/theme"
import Select from "../atoms/Select"
import Information from "../organisms/information"

const StyledMain = styled.div`
  margin-top: 5rem;

  @media (max-width: ${theme.breakpoints.tablet}) {
    margin-top: 0;
  }

  h2 {
    font-size: 3rem;
    text-transform: uppercase;
    color: ${theme.colors.bodyFont};
  }

  .sort-info {
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr;
    grid-column-gap: 2rem;

    @media (max-width: ${theme.breakpoints.tablet}) {
      display: block;

      section:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
  }

  .available-message {
    color: #c8102e;
    border-bottom: 1px solid black;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 1.8rem;
  }

  .display-sort {
    display: grid;
    grid-template-columns: 2fr 1fr;

    @media (max-width: ${theme.breakpoints.mobileMd}) {
      grid-template-columns: 1fr;
    }

    section {
      display: grid;
      span {
        display: inline-block;
        width: 50%;
        font-size: 2.5rem;
        font-weight: 700;
        align-self: center;
        text-transform: uppercase;
        color: ${theme.colors.homeDepot.primary};
        transition: outline 0.2s ease;
        cursor: pointer;

        @media (max-width: ${theme.breakpoints.desktop}) {
          width: 80%;
        }

        &:hover {
          color: ${theme.colors.homeDepot.hover};
        }

        @media (max-width: ${theme.breakpoints.mobileMd}) {
          width: 100%;
          margin-bottom: 2rem;
        }
      }
    }
  }

  select,
  input {
    font-size: 1.6rem;
    outline: none;
    background-color: rgb(248, 248, 248);
  }

  select {
    border: 1px solid rgb(166, 166, 166);
    border-radius: 5px;
    padding: 10px;
    height: 40px;
  }
`

class MainPage extends Component {
  constructor(props) {
    super(props)

    this.props = props
    this.state = {
      manufacturer: "Show All",
      osi: "",
      idhmaxNum: "",
      sort: "osi",
    }
  }

  updateState = e => {
    this.updateManufacturer(e)
    this.updateColor(e)
  }

  updateManufacturer = e => {
    this.setState({ manufacturer: e.target.value })
    if (e.target.value !== "Show All") this.setState({ sort: "colorName" })
  }

  updateColor = e => {
    let colorInfo = this.props.manufacturers.find(
      manufacturer => manufacturer.name === e.target.value
    )
    this.setState({
      colorInfo: colorInfo,
    })
  }

  updateOsi = e => {
    this.setState({ osi: e.target.value })
  }

  updateIdhmax = e => {
    this.setState({ idhmaxNum: e.target.value })
  }

  updateSort = e => {
    this.setState({ sort: e.target.value })
  }

  resetState = () => {
    this.setState({
      manufacturer: "Show All",
      colorInfo: null,
      sort: "osi",
      osi: "",
      idhmaxNum: "",
    })
  }

  getManufacturers = () => {
    let options = [{ value: `Show All`, label: `Show All` }]

    this.props.manufacturers.forEach(manufacturer => {
      if (manufacturer.color_cross_refs.length > 0)
        options.push({ value: manufacturer.name, label: manufacturer.name })
    })

    return options
  }

  getSortingOptions = () => {
    let options = []

    if (this.state.manufacturer !== "Show All")
      options.push({ value: "colorname", label: "Color Name" })

    options.push(
      { value: "osi", label: "OSI Number" },
      { value: "idhmax", label: "QUAD Max IDH Number" },
      { value: "idh", label: "QUAD IDH Number" }
    )

    return options
  }

  render() {
    return (
      <StyledMain>
        <Information className="hide-mobile" />
        <h2>Find a Color</h2>
        <div className="sort-info">
          <Input
            placeholder="003"
            onChange={this.updateOsi}
            value={this.state.osi}
            name="osi"
            label="By OSI Number:"
          />
          <Input
            placeholder="1868687"
            onChange={this.updateIdhmax}
            value={this.state.idhmaxNum}
            name="quadMaxIDH"
            label="By IDH Number:"
          />
          <Select
            options={this.getManufacturers()}
            value={this.state.manufacturer}
            onChange={this.updateState}
            label="By Manufacturer"
          />
      </div>
      <p class="available-message">
        QUAD MAX Products highlighted in RED are temporarily unavailable
      </p>
        <section className="display-sort">
          <section>
            <span
              role="button"
              onClick={this.resetState}
              onKeyDown={this.resetState}
              tabIndex={0}
            >
              Reset Current Display
            </span>
          </section>
          <Select
            options={this.getSortingOptions()}
            value={this.state.sort}
            onChange={this.updateSort}
            label="Sort these colors by:"
          />
        </section>
        <ShowColors
          colorInfo={this.state.colorInfo}
          osi={this.state.osi}
          idhmaxNum={this.state.idhmaxNum}
          sort={this.state.sort}
        />
      </StyledMain>
    )
  }
}

export default MainPage
