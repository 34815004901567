import React from "react"
import styled from "@emotion/styled"
import { theme } from "../particles/theme"

const StyledColorBlock = styled.section`
  font-size: 1.4rem;
  border-bottom: 1px solid black;
  padding-bottom: 1.5rem;

  p {
    margin: 0;
    strong {
      color: #666;
    }
  }

  p.color-name {
    margin-bottom: 1.5rem;
  }

  p.unavailable {
    color: #c8102e;
    strong {
      color: #c8102e;
    }
  }

  section {
    height: 100px;
    width: 100%;
    margin-bottom: 1.5rem;
    position: relative;

    ${props =>
      props.clear
        ? `
      &::after {
        content: "Clear";
        position: absolute;
        left: 1rem;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 700;
        top: calc(50px - 1rem);
        color: ${theme.colors.homeDepot.gray};
      }
    `
        : undefined}
  }

  a {
    width: 100%;
    background: ${theme.colors.homeDepot.primary};
    display: inline-block;
    text-decoration: none;
    padding: 1rem 0;
    color: #fff;
    font-size: 1.8rem;
    text-align: center;
    cursor: pointer;
    box-sizing: content-box;
    margin: 1rem 0 0;
    border: 0;
    border-bottom: 3px solid ${theme.colors.homeDepot.hover};
    transition: 0.3s;
  }

  a:hover {
    background: ${theme.colors.homeDepot.hover};
  }
`

const ColorBlock = ({ osi, idh, idhmax, rgb, color_name, link, unavailable }) => {
  return (
    <StyledColorBlock clear={rgb.toUpperCase() === "FFFFFF" ? 1 : 0}>
      <section style={{ background: `#${rgb}` }} />
      {color_name ? <p className="color-name">{color_name}</p> : ""}

      <p>
        <strong>OSI Color Code:</strong> {osi}
      </p>
      <p>
        <strong>QUAD IDH#:</strong> {idh}
      </p>

      { unavailable
        ? <p class='unavailable'><strong>QUAD MAX IDH#:</strong> {idhmax}<br /><strong>(Temp. Unavailable)</strong></p>
        : <p><strong>QUAD MAX IDH#:</strong> {idhmax}</p>
      }

      <a href={link} onClick={() => { let dataLayer = window.dataLayer || []; dataLayer.push({ event: `IDH: ${idh}` }) }}>
        Buy Now
      </a>
    </StyledColorBlock>
  )
}

export default ColorBlock
