import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/templates/layout"
import SEO from "../components/seo"
import MainPage from "../components/templates/MainPage"

export const query = graphql`
  query MyQuery {
    allMysqlManufacturers {
      totalCount
      nodes {
        name
        color_cross_refs {
          adjusted_rgb
          color_name
          idh
          link
          idhmax
          osi
          unavailable
        }
      }
    }
  }
`
const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home Depot - Color Match Tool" />
    <MainPage manufacturers={data.allMysqlManufacturers.nodes} />
  </Layout>
)

export default IndexPage
