import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

import ColorBlock from "./molecules/ColorBlock"
import { theme } from "./particles/theme"

const StyledColorDisplay = styled.div`
  margin-top: 3rem;
  display: -ms-flexbox;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-auto-flow: dense;

  @media (max-width: ${theme.breakpoints.desktop}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: ${theme.breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${theme.breakpoints.mobileMd}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${theme.breakpoints.mobileSm}) {
    grid-template-columns: 1fr;
  }

  h3 {
    grid-column: 1/7;
    grid-row: 6;
    text-align: center;
  }
`

const ColorComponent = (color, idx, colorInfo) => (
  <ColorBlock
    key={color.id + " " + idx}
    osi={color.osi}
    idh={color.idh}
    idhmax={color.idhmax}
    link={color.link}
    rgb={colorInfo ? color.adjusted_rgb : color.colors.adjusted_rgb}
    color_name={colorInfo ? color.color_name : null}
    unavailable={color.unavailable}
  />
)

const sortColors = (colors, sort) => {
  switch (sort) {
    case "colorName":
      return colors.sort((a, b) => {
        if (a.color_name < b.color_name) {
          return -1
        }
        if (a.color_name > b.color_name) {
          return 1
        }
        return 0
      })
    case "idh":
      return colors.sort((a, b) => {
        if (a.idh === "") return 1
        else if (b.idh === "") return -1
        else return a.idh - b.idh
      })
    case "idhmax":
      return colors.sort((a, b) => {
        if (a.idhmax === "") return 1
        else if (b.idhmax === "") return -1
        else return a.idhmax - b.idhmax
      })
    default:
      return colors.sort((a, b) => a.osi - b.osi)
  }
}

const ShowColors = ({ colorInfo, osi, idhmaxNum, sort }) => {
  const { allMysqlOsi2Thd } = useStaticQuery(graphql`
    query AllColors {
      allMysqlOsi2Thd {
        nodes {
          osi
          idh
          idhmax
          link
          unavailable
          colors {
            adjusted_rgb
          }
        }
      }
    }
  `)

  let colors = colorInfo ? colorInfo.color_cross_refs : allMysqlOsi2Thd.nodes
  let isColor = 0
  sortColors(colors, sort)

  // sort again, this time to move unavailable colors to end
  colors.sort((a,b) => { return a.unavailable - b.unavailable })

  return (
    <StyledColorDisplay>
      {colors.map((color, idx) => {
        if (osi && color.osi.match(`^${osi}`)) {
          isColor = 1
          return ColorComponent(color, idx, colorInfo)
        } else if (
          idhmaxNum &&
          (color.idhmax.match(`^${idhmaxNum}`) ||
            color.idh.match(`^${idhmaxNum}`))
        ) {
          isColor = 1
          return ColorComponent(color, idx, colorInfo)
        } else if (!idhmaxNum && !osi) {
          isColor = 1
          return ColorComponent(color, idx, colorInfo)
        } else {
          return null
        }
      })}
      {!isColor ? <h3>No Results to display</h3> : undefined}
    </StyledColorDisplay>
  )
}

export default ShowColors
