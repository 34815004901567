import React from "react"
import styled from "@emotion/styled"
import { theme } from "../particles/theme"

const StyledSelect = styled.section`
  label {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    display: block;
  }

  select {
    border: 0;
    padding: 10px;
    height: 40px;
    font-size: 1.6rem;
    outline: none;
    display: block;
    background: ${(props: StyledProps) => props.background};
    outline: ${(props: StyledProps) =>
      `${props.border.size}px ${props.border.type} ${props.border.color}`};
    transition: outline 0.2s ease;

    @media (max-width: ${theme.breakpoints.tablet}) {
      width: 100% !important;
    }

    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
`

const Select = ({
  options,
  value,
  border = { size: 1, type: `solid`, color: `black` },
  backgroundColor = "rgb(248, 248, 248)",
  name,
  label,
  onChange,
}: Props) => {
  return (
    <StyledSelect background={backgroundColor} border={border}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <select name={name} onChange={onChange} value={value}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </StyledSelect>
  )
}

type Border = {
  size: number
  type: string
  color: string
}

type Props = {
  options: [{ value: string; label: string }]
  value: string

  border?: Border
  backgroundColor?: string
  name?: string
  label?: string
  onChange?: () => void
}

type StyledProps = {
  background: string
  border: Border
}

export default Select
